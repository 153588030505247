<template>
  <div>
    <fractions-menu v-if="loggedIn"></fractions-menu>
    <keep-alive>
      <router-view @login="doLogin" @messages="newMessages" :key="$route.fullPath"></router-view>
    </keep-alive>
    <messaging :messages="messages" @dismiss="dismiss" v-if="show"></messaging>

  </div>
</template>

<script>
import fractionsMenu from './menu.vue'
import messaging from './messaging.vue'

export default {
  name: 'App',
  data() {
    return {
      "loggedIn": false,
      messages: []
    }
  },
  computed: {
    show() {
      if(this.messages.length > 0) return true;
      return false;
    }
  },
  methods: {
    doLogin() {
      this.loggedIn = Auth.isLoggedIn();
    },
    fetchMessages() {

      fetch('/api/messages')
        .then(res => res.json())
        .then(res => {
          this.messages = res;
          this.loading = false;
        })
        .catch(err => {
          console.log(err);
        });

    },
    dismiss() {
      fetch('/api/messages',{
          method: 'put',
          body: JSON.stringify({
            id: this.messages[0].id
          }),
          headers: {
            'content-type': 'application/json',
            'X-CSRF-TOKEN': window.csrfToken
          }
        })
        .then(res => res.json())
        .then(res => {
          this.messages.shift();  // pops off the first one
        })
        .catch(err => {
          console.log(err);
        });
    },
    newMessages(payload) {
      this.messages = payload;
    }
  },
  components: {
    fractionsMenu,
    messaging
  },
  created() {
    this.doLogin();
  },
  watch: {
    loggedIn() {
      //if(this.loggedIn) this.fetchMessages();
    }
  }
    // empty
}
</script>
