
<template>
    <div class="text-center">
      <div class="c">

        <div class="row mt-5 mb-4">

          <div class="ml-auto"><button @click="$router.go(-1)" class="ml-auto btn btn-primary"><i class="fa fa-arrow-left"></i> Logs</button></div>

        </div>

        <h4 style="white-space:nowrap;">{{ row.message }}</h4>

        <div class="log" v-if="row">



          <table class="log-table">
              <thead>
                <tr>
                  <th class="secondary">Channel</th>
                  <th class="secondary">Timestamp</th>
                  <th class="secondary">Level</th>
                  <th class="secondary">Level</th>
                  <!-- <th class="secondary">Message</th> -->
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ row.channel }}</td>
                  <td>{{ row.datetime.date }}</td>
                  <td>{{ row.level }}</td>
                  <td>{{ row.level_name }}</td>
                  <!-- <td style="text-align:left;max-width:50vw;overflow:hidden;text-overflow:ellipsis;">{{ row.message }}</td> -->
                </tr>
              </tbody>
          </table>
        </div>

        <div class="context">
          <h4>Context</h4>
          <table class="striped">
                <tr>
                  <th class="secondary">class</th><td>{{ row.context.class }}</td>
                </tr>
                <tr>
                  <th class="secondary">file</th><td>{{ row.context.file }}</td>
                </tr>
                <tr>
                  <th class="secondary">line</th><td>{{ row.context.line }}</td>
                </tr>

          </table>
        </div>
        <div class="trace" v-if="trace">
          <h4>Trace</h4>

          <div v-for="item in trace">
            {{ item }}
          </div>

        </div>

        <div class="extra">
          <h4>Extra</h4>
          <table class="striped">
                <tr v-for="val, key in row.extra">
                  <th class="secondary" v-if="val">{{ key }}</th><td>{{ val }}</td>
                </tr>

          </table>
        </div>

      </div>
    </div>
</template>

<script>
import * as library from 'js-library'

export default {
  props: ['endpoint'],
  name: 'login',
  data: function() {
    return {
      row: {
        context: {
          trace: ""
        }
      },
      search: '',
      level: '',
      file: '',
      files: []
    }
  },
  computed: {
    id() {
      return this.$route.params.id || this.attributes.id;
    },
    trace() {
      if(this.row.context.trace) return this.row.context.trace.split("\n");
      else return null;
    }
  },
  methods: {
    fetchLog() {
      // fetch(this.endpoint + '?id=' + this.id, {
      fetch(this.endpoint + '/' + this.id, {
        headers: {
          'Content-Type': 'application/json',
          'X-TOKEN': Auth.token()
        }
      })
      .then(res => checkResponse(res))
      .then(res => res.json())
      .then(res => {
        console.log(res);

        this.row = res;

      })
      .catch(err => console.log(err))
    }
  },
  created: function() {
    // console.log(this.$route.query.to);
    // console.log("endpoint: " + this.endpoint);
    console.log(this.$route.params);
    if(this.$route.params.log) {
      this.row = this.$route.params.log;
    }
    else this.fetchLog();
  },
  updated: function() {
    // console.log(this.$route.query.to);
    // console.log("endpoint: " + this.endpoint);
    console.log(this.$route.params);
    if(this.$route.params.log) {
      this.row = this.$route.params.log;
    }
    else this.fetchLog();
  },
  components: {

  }
}
</script>

<style scoped>
.log {
  border: 1px solid rgb(50,100, 250,.1);
  /* background: rgb(50,100, 250,.1); */
  border-radius: 6px;
  padding: 10px;
  margin: 10px;
}
.log-table th, .log-table, td {
  padding-left: 10px;
  padding-right: 10px;
  min-width: 80px;
  white-space: nowrap;
}
.context {

  border: 1px solid rgb(75,150, 100,.2);
  background: rgb(75,150, 100,.1);
  border-radius: 6px;
  padding: 10px;
  margin: 10px;
  text-align: left;
}
.extra {
  /* border: 1px solid rgb(150,75, 100,.2);
  background: rgb(150,75, 100,.1); */
  border: 1px solid rgb(50,100, 250,.2);
  background: rgb(50,100, 250,.1);

  border-radius: 6px;
  padding: 10px;
  margin: 10px;
  text-align: left;
}
.log {
  border: 1px solid rgb(50,100, 250,.2);
  background: rgb(50,100, 250,.1);

  border-radius: 6px;
  padding: 10px;
  margin: 10px;
  text-align: left;
}
.trace {

  border: 1px solid rgb(150,150, 175,.2);
  background: rgb(150,150, 175,.1);
  border-radius: 6px;
  padding: 10px;
  margin: 10px;
  text-align: left;
}
.c {
  display: inline-block;
}

</style>
