
<template>
    <div class="text-center p-5">
      <div class="main">

        <div class="mt-2 mb-4">
          <h2>Queue</h2>
        </div>

        <table class="table-striped">
            <thead>
              <tr>
                <th class="secondary">interval</th>
                <th class="secondary">pending</th>
                <th class="secondary">completed</th>
                <th class="secondary">failed</th>
                <th class="secondary">total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <select v-model="row.interval" @change=fetchItems>
                    <option value="5">5m</option>
                    <option value="15">15m</option>
                    <option value="30">30m</option>
                    <option value="60">60m</option>
                    <option value="240">4hr</option>
                    <option value="1440">day</option>
                    <option value="2880">2day</option>
                    <option value="10000">week</option>
                  </select>
                </td>
                <td>{{ row.pending }}</td>
                <td>{{ row.completed }}</td>
                <td>{{ row.failed }}</td>
                <td>{{ row.total }}</td>
              </tr>
            </tbody>
        </table>

      </div>
    </div>
</template>

<script>
import * as library from 'js-library'

export default {
  props: ['endpoint'],
  name: 'queuesummary',
  data: function() {
    return {
      row: {
        interval: 15,
        pending: 0,
        completed: 0,
        failed: 0,
        total: 0
      }
    }
  },
  methods: {
    fetchItems() {
      fetch(this.endpoint + '?interval=' + this.row.interval, {
        headers: {
          'Content-Type': 'application/json',
          'X-TOKEN': Auth.token()
        }
      })
      .then(res => checkResponse(res))
      .then(res => res.json())
      .then(res => {
        console.log(res);
        this.row = res;
      })
      .catch(err => console.log(err))
    }
  },
  created: function() {
    console.log("endpoint: " + this.endpoint);
    this.fetchItems();
    console.log(this.$route.query)
  },
  components: {

  }
}
</script>

<style scoped>
.search {
  display: inline-block;
  text-align: center;
  margin: 10px;
  /* border: 1px solid #ccc; */
  background: #f9f9fc;
  border-radius: 6px;
  padding: 25px;

}
.search div {
  margin: 6px;
}
.search .label {
  width: 60px;
  display: inline-block;
  text-align: right;
  /* border: 1px solid red; */
}
input, select {
  width: 300px;
  padding: 5px;
}
td {
  padding: 5px;

}
td.comment {
  width: 100px;
  overflow:hidden;
}
.main {
  display: inline-block;
  /* max-width: 80vw; */
}
th {
  color: white;
  background: #475869;
  padding: 10px;
  font-weight: normal;
  position: sticky;
  top: 46px;
}
</style>
