
<template>
    <div>
      <div class="container p-1 text-left">

        <div class="row mt-5 mb-4">
          <h2>{{ $route.meta.title || "View Capacity Release Detail" }}</h2>
          <div class="ml-auto"><button @click="$router.go(-1)" class="ml-auto btn btn-primary"><i class="fa fa-arrow-left"></i> Contracts</button></div>
        </div>

        <table class="table table-bordered">
          <tr v-show="attributes.pipeline.name !== null">
            <th>Pipeline</th><td>{{ attributes.pipeline.name }}</td>
          </tr>
          <tr v-show="attributes.contract_number !== null">
            <th>Contract Number</th><td>{{ attributes.contract_number }}</td>
          </tr>
          <tr v-show="attributes.shipper.name !== null">
            <th>Shipper</th><td>{{ attributes.shipper.name }}</td>
          </tr>
          <tr v-show="attributes.rateschedule.name !== null">
            <th>Rate Schedule</th><td>{{ attributes.rateschedule.name }}</td>
          </tr>
          <tr v-show="attributes.svc_level !== null">
            <th>Service Level</th><td>{{ attributes.svc_level }}</td>
          </tr>
          <tr v-show="attributes.date_begin !== null">
            <th>Date Begin</th><td>{{ attributes.date_begin }}</td>
          </tr>
          <tr v-show="attributes.date_end !== null">
            <th>Date End</th><td>{{ attributes.date_end }}</td>
          </tr>
          <tr v-show="attributes.kmdq !== null">
            <th>MDQ</th><td>{{ attributes.kmdq }}</td>
          </tr>
          <tr v-show="attributes.negotiated !== null">
            <th>Negotiated</th><td>{{ attributes.negotiated == 1 ? 'Yes' : 'No' }}</td>
          </tr>
          <tr v-show="attributes.is_capacityrelease !== null">
            <th>Capacity Release</th><td>{{ attributes.is_capacityrelease == 1 ? 'Yes' : 'No' }}</td>
          </tr>
        </table>

        <div class="mt-5" v-if="attributes.locations.length">
          <h4>Locations</h4>
          <table class="table table-bordered mt-2">
            <thead>
              <tr>
                <th>Loc No</th><th>Loc Name</th><th>Dir</th><th>Vol</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="loc in attributes.locations">
                <td>{{ loc.location.location_number }}</td>
                <td>{{ loc.location.location_name }}</td>
                <td>{{ loc.direction }}</td>
                <td>{{ loc.volume }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="mt-5" v-if="attributes.agents.length">
          <h4>Agents</h4>
          <table class="table table-bordered mt-2">
            <thead>
              <tr>
                <th>Agent Name</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="loc in attributes.locations">
                <td>{{ agent.name }}</td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>
    </div>
</template>

<script>

import * as library from 'js-library'

export default {
  name: 'contractDetail',
  props: {
    endpoint : {
      default: function() {
        return null;
      }
    },
    title : {
      default: function() {
        return null;
      }
    }
  },
  data: function() {
    return {
      attributes: {
        id: null,
        pipeline: {},
        contract_number: null,
        shipper: {},
        rateschedule: {},
        svc_level: null,
        date_begin: null,
        date_end: null,
        kmdq: null,
        negotiated: null,
        is_capacityrelease: null,
        locations: [],
        agents: []
      }
    }
  },
  computed: {
    id() {
      return this.$route.params.id || this.attributes.id;
    },
  },
  methods: {
    fetchItem() {
      return new Promise(function(resolve,reject) {
        fetch(this.endpoint + '/' + this.$route.params.id, {
            headers: {
              'Content-Type': 'application/json',
              'X-TOKEN': Auth.token()
            }
          })
          .then(res => res.json())
          .then(res => {
            for(var item in res) {
              this.attributes[item] = res[item];
            }
            this.loading = false;
            resolve(res);
          })
          .catch(err => {
            console.log(err);
            reject(err);
          });
        }.bind(this));
    },
  },
  created: function() {
    console.log("wa da tay");
    this.fetchItem();
  },
  watch: {
    '$route': function(to, from) {
      console.log("endpoint change",to,from);
      this.loading = true;
      this.fetchItem();
    }
  },
  components: {

  }
}
</script>

<style scoped>
.card table th, .card table td {
  border-top: none;
}
.row {
  width: 100%;
  text-align: center;
}
</style>
