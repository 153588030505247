
<template>
    <div>
      <div class="container p-1 text-left">

        <div class="row mt-5 mb-4">
          <h2>{{ $route.meta.title || "View Capacity Release Detail" }}</h2>
          <div class="ml-auto"><router-link to="/capacityRelease" class="ml-auto btn btn-primary"><i class="fa fa-arrow-left"></i> Capacity Release</router-link></div>
        </div>

        <table class="table table-bordered">
          <tr v-show="attributes.pipeline.name !== null">
            <th>Pipeline</th><td>{{ attributes.pipeline.name }}</td>
          </tr>
          <tr v-show="attributes.post_date !== null">
            <th>Post Date</th><td>{{ attributes.post_date }}</td>
          </tr>
          <tr v-show="attributes.post_date !== null">
            <th>Post Time</th><td>{{ attributes.post_time }}</td>
          </tr>
          <tr v-show="attributes.releasing_shipper.name !== null">
            <th>Releasing Shipper</th><td>{{ attributes.releasing_shipper.name }}</td>
          </tr>
          <tr v-show="attributes.acquiring_shipper.name !== null">
            <th>Acquiring Shipper</th><td>{{ attributes.acquiring_shipper.name }}</td>
          </tr>
          <tr v-show="attributes.affiliation !== null">
            <th>Affiliation</th><td>{{ attributes.affiliation }}</td>
          </tr>
          <tr v-show="attributes.releasing_contract.contract_number !== null">
            <th>Releasing Contract</th><td>{{ attributes.releasing_contract.contract_number }}</td>
          </tr>
          <tr v-show="attributes.acquiring_contract.contract_number !== null">
            <th>Acquiring Contract</th><td>{{ attributes.acquiring_contract.contract_number }}</td>
          </tr>
          <tr v-show="attributes.date_begin !== null">
            <th>Date Begin</th><td>{{ attributes.date_begin }}</td>
          </tr>
          <tr v-show="attributes.date_end !== null">
            <th>Date End</th><td>{{ attributes.date_end }}</td>
          </tr>
          <tr v-show="attributes.date_awarded !== null">
            <th>Date Awarded</th><td>{{ attributes.date_awarded }}</td>
          </tr>
          <tr v-show="attributes.rateschedule.name !== null">
            <th>Rate Schedule</th><td>{{ attributes.rateschedule.name }}</td>
          </tr>
          <tr v-show="attributes.offer_number !== null">
            <th>Offer Number</th><td>{{ attributes.offer_number }}</td>
          </tr>
          <tr v-show="attributes.award_number !== null">
            <th>Award Number</th><td>{{ attributes.award_number }}</td>
          </tr>
          <tr v-show="attributes.mdq !== null">
            <th>MDQ</th><td>{{ attributes.mdq }}</td>
          </tr>
          <tr v-show="attributes.msq !== null">
            <th>MSQ</th><td>{{ attributes.msq }}</td>
          </tr>
          <tr v-show="attributes.mdiq !== null">
            <th>MDIQ</th><td>{{ attributes.mdiq }}</td>
          </tr>
          <tr v-show="attributes.mdwq !== null">
            <th>MDWQ</th><td>{{ attributes.mdwq }}</td>
          </tr>
          <tr v-show="attributes.rate !== null">
            <th>Rate</th><td>{{ attributes.rate }}</td>
          </tr>
          <tr v-show="attributes.msq_rate !== null">
            <th>MSQ Rate</th><td>{{ attributes.msq_rate }}</td>
          </tr>
          <tr v-show="attributes.mdwq_rate !== null">
            <th>MDWQ Rate</th><td>{{ attributes.mdwq_rate }}</td>
          </tr>
          <tr v-show="attributes.mdiq_rate !== null">
            <th>MDIQ Rate</th><td>{{ attributes.mdiq_rate }}</td>
          </tr>
          <tr v-show="attributes.com_rate !== null">
            <th>Com Rate</th><td>{{ attributes.com_rate }}</td>
          </tr>
          <tr v-show="attributes.overrun_rate !== null">
            <th>Overrun Rate</th><td>{{ attributes.overrun_rate }}</td>
          </tr>
          <tr v-show="attributes.rate_basis !== null">
            <th>Rate Basis</th><td>{{ attributes.rate_basis }}</td>
          </tr>
          <tr v-show="attributes.volumetric !== null">
            <th>Volumetric</th><td>{{ attributes.volumetric }}</td>
          </tr>
          <tr v-show="attributes.percent_bid !== null">
            <th>Percent Bid</th><td>{{ attributes.percent_bid }}</td>
          </tr>
          <tr v-show="attributes.prearranged !== null">
            <th>Prearranged</th><td>{{ attributes.prearranged == 1 ? 'Yes' : 'No' }}</td>
          </tr>
          <tr v-show="attributes.biddable !== null">
            <th>Biddable</th><td>{{ attributes.biddable == 1 ? 'Yes' : 'No' }}</td>
          </tr>
          <tr v-show="attributes.prev_release !== null">
            <th>Prev Release</th><td>{{ attributes.prev_release == 1 ? 'Yes' : 'No' }}</td>
          </tr>
          <tr v-show="attributes.ama !== null">
            <th>AMA</th><td>{{ attributes.ama == 1 ? 'Yes' : 'No' }}</td>
          </tr>
          <tr v-show="attributes.rcp !== null">
            <th>RCP</th><td>{{ attributes.rcp == 1 ? 'Yes' : 'No' }}</td>
          </tr>
          <tr v-show="attributes.index_based !== null">
            <th>Index Based</th><td>{{ attributes.index_based == 1 ? 'Yes' : 'No' }}</td>
          </tr>
          <tr v-show="attributes.perc_of_max !== null">
            <th>Percent of Max</th><td>{{ attributes.perc_of_max == 1 ? 'Yes' : 'No' }}</td>
          </tr>
          <tr v-show="attributes.negotiated !== null">
            <th>Negotiated</th><td>{{ attributes.negotiated == 1 ? 'Yes' : 'No' }}</td>
          </tr>
          <tr v-show="attributes.market_based_rate !== null">
            <th>Market Based</th><td>{{ attributes.market_based_rate == 1 ? 'Yes' : 'No' }}</td>
          </tr>
          <tr v-show="attributes.business_day !== null">
            <th>Business Day</th><td>{{ attributes.business_day == 1 ? 'Yes' : 'No' }}</td>
          </tr>
          <tr v-show="attributes.surcharge !== null">
            <th>Surcharge</th><td>{{ attributes.surcharge == 1 ? 'Yes' : 'No' }}</td>
          </tr>
          <tr v-show="attributes.discounted !== null">
            <th>Discounted</th><td>{{ attributes.discounted == 1 ? 'Yes' : 'No' }}</td>
          </tr>
          <tr v-show="attributes.points_amendable !== null">
            <th>Points Amendable</th><td>{{ attributes.points_amendable == 1 ? 'Yes' : 'No' }}</td>
          </tr>
          <tr v-show="attributes.storage_inventory_release !== null">
            <th>Storage Inventory Release</th><td>{{ attributes.storage_inventory_release == 1 ? 'Yes' : 'No' }}</td>
          </tr>
          <tr v-show="attributes.permanent !== null">
            <th>Permanent</th><td>{{ attributes.permanent == 1 ? 'Yes' : 'No' }}</td>
          </tr>
          <tr v-show="attributes.recallable !== null">
            <th>Recallable</th><td>{{ attributes.recallable == 1 ? 'Yes' : 'No' }}</td>
          </tr>
          <tr v-show="attributes.reput !== null">
            <th>Reput</th><td>{{ attributes.reput == 1 ? 'Yes' : 'No' }}</td>
          </tr>
          <tr v-show="attributes.reput_buyer_option !== null">
            <th>Reput Buyer Option</th><td>{{ attributes.reput_buyer_option == 1 ? 'Yes' : 'No' }}</td>
          </tr>
          <tr v-show="attributes.re_releasable !== null">
            <th>Re-Releaseable</th><td>{{ attributes.re_releasable == 1 ? 'Yes' : 'No' }}</td>
          </tr>
          <tr v-show="attributes.recall_timely !== null">
            <th>Recall Timely</th><td>{{ attributes.recall_timely == 1 ? 'Yes' : 'No' }}</td>
          </tr>
          <tr v-show="attributes.recall_ee !== null">
            <th>Recall EE</th><td>{{ attributes.recall_ee == 1 ? 'Yes' : 'No' }}</td>
          </tr>
          <tr v-show="attributes.recall_eve !== null">
            <th>Recall Evening</th><td>{{ attributes.recall_eve == 1 ? 'Yes' : 'No' }}</td>
          </tr>
          <tr v-show="attributes.recall_id1 !== null">
            <th>Recall ID1</th><td>{{ attributes.recall_id1 == 1 ? 'Yes' : 'No' }}</td>
          </tr>
          <tr v-show="attributes.recall_id2 !== null">
            <th>Recall ID2</th><td>{{ attributes.recall_id2 == 1 ? 'Yes' : 'No' }}</td>
          </tr>
          <tr v-show="attributes.recall_id3 !== null">
            <th>Recall ID3</th><td>{{ attributes.recall_id3 == 1 ? 'Yes' : 'No' }}</td>
          </tr>
          <tr v-show="attributes.recall_terms !== null">
            <th>Recall Terms</th><td>{{ attributes.recall_terms == 1 ? 'Yes' : 'No' }}</td>
          </tr>
          <tr v-show="attributes.special_terms !== null">
            <th>Special Terms</th><td>{{ attributes.special_terms == 1 ? 'Yes' : 'No' }}</td>
          </tr>
          <tr v-show="attributes.secondary_point_access !== null">
            <th>Secondary Point Access</th><td>{{ attributes.secondary_point_access == 1 ? 'Yes' : 'No' }}</td>
          </tr>
          <tr v-show="attributes.ama_terms !== null">
            <th>AMA Terms</th><td>{{ attributes.ama_terms }}</td>
          </tr>
          <tr v-show="attributes.zones !== null">
            <th>Zones</th><td>{{ attributes.zones }}</td>
          </tr>


        </table>

        <div class="mt-5" v-if="attributes.locations.length">
          <h4>Locations</h4>
          <table class="table table-bordered mt-2">
            <thead>
              <tr>
                <th>Loc No</th><th>Loc Name</th><th>Dir</th><th>Vol</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="loc in attributes.locations">
                <td>{{ loc.location.location_number }}</td>
                <td>{{ loc.location.location_name }}</td>
                <td>{{ loc.direction }}</td>
                <td>{{ loc.volume }}</td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>
    </div>
</template>

<script>

import * as library from 'js-library'

export default {
  name: 'capacityReleaseDetail',
  props: {
    endpoint : {
      default: function() {
        return null;
      }
    },
    title : {
      default: function() {
        return null;
      }
    }
  },
  data: function() {
    return {
      attributes: {
        id: null,
        pipeline: {},
        download_date: null,
        post_date: null,
        post_time: null,
        releasing_shipper: {},
        acquiring_shipper: {},
        affiliation: null,
        releasing_contract: {},
        acquiring_contract: {},
        date_begin: null,
        date_end: null,
        date_awarded: null,
        //rec_location: null,
        //del_location: null,
        rateschedule: {},
        offer_number: null,
        award_number: null,
        mdq: null,
        msq: null,
        mdiq: null,
        mdwq: null,
        rate: null,
        msq_rate: null,
        mdwq_rate: null,
        mdiq_rate: null,
        com_rate: null,
        overrun_rate: null,
        rate_basis: null,
        volumetric: null,
        percent_bid: null,
        prearranged: null,
        biddable: null,
        prev_release: null,
        ama: null,
        rcp: null,
        index_based: null,
        perc_of_max: null,
        negotiated: null,
        market_based_rate: null,
        business_day: null,
        surcharge: null,
        discounted: null,
        points_amendable: null,
        storage_inventory_release: null,
        permanent: null,
        recallable: null,
        reput: null,
        reput_buyer_option: null,
        re_releasable: null,
        recall_timely: null,
        recall_ee: null,
        recall_eve: null,
        recall_id1: null,
        recall_id2: null,
        recall_id3: null,
        recall_terms: null,
        special_terms: null,
        secondary_point_access: null,
        ama_terms: null,
        zones: null,
        locations: []
      }
    }
  },
  computed: {
    id() {
      return this.$route.params.id || this.attributes.id;
    },
  },
  methods: {
    fetchItem() {
      return new Promise(function(resolve,reject) {
        fetch(this.endpoint + '/' + this.$route.params.id, {
            headers: {
              'Content-Type': 'application/json',
              'X-TOKEN': Auth.token()
            }
          })
          .then(res => res.json())
          .then(res => {
            for(var item in res) {
              this.attributes[item] = res[item];
            }
            this.loading = false;
            resolve(res);
          })
          .catch(err => {
            console.log(err);
            reject(err);
          });
        }.bind(this));
    },
  },
  created: function() {
    console.log("wa da tay");
    this.fetchItem();
  },
  watch: {
    '$route': function(to, from) {
      console.log("endpoint change",to,from);
      this.loading = true;
      this.fetchItem();
    }
  },
  components: {

  }
}
</script>

<style scoped>
.card table th, .card table td {
  border-top: none;
}
.row {
  width: 100%;
  text-align: center;
}
</style>
