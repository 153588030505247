
<!-- this view is the same as editUser but intended for the user not the admin -->
<template>
    <div>
      <div class="container p-1">
        <div class="row mt-5 mb-4">
          <h2>{{ $route.meta.title || "Edit User" }}</h2>
          <!-- <div class="ml-auto"><router-link to="/users" class="ml-auto btn btn-primary"><i class="fa fa-arrow-left"></i> Users</router-link></div> -->
        </div>
        <div class="card card-body bg-light mb-4">

          <div class="form-group">
            <label class="col-sm-4 col-form-label">Current Password</label>
            <input type="password" class="form-control" v-model="attributes.current_password">
          </div>

          <div class="form-group">
            <label class="col-sm-4 col-form-label">New Password</label>
            <input type="password" class="form-control" v-model="attributes.new_password">
          </div>

          <div class="form-group">
            <label class="col-sm-4 col-form-label">Confirm New Password</label>
            <input type="password" class="form-control" v-model="attributes.confirm_new_password">
          </div>

          <div class="text-danger mb-4 mx-auto" v-for="err in errors" v-text="err.error"></div>

        </div>

        <div class="text-center">
          <button @click="save" class="btn btn-success ml-auto mr-4"><i class="fa fa-check"></i> Save</button>
          <router-link to="." class="btn btn-secondary mr-auto"><i class="fa fa-times"></i> Cancel</router-link>
        </div>
      </div>
    </div>
</template>

<script>
  export default {
    data() {
      return {
        attributes: {
          current_password: '',
          new_password: '',
          confirm_new_password: ''
        },
        errors: []
      }
    },
    created() {

    },
    methods: {
      save() {
        fetch('/api/updatePassword', {
          method: 'post',
          body: JSON.stringify(this.attributes),
          headers: {
            'content-type': 'application/json',
            'X-TOKEN': Auth.token()
          }
        })
        .then(res => this.checkResponse(res))
        .then(res => res.json())
        .then( res => {
          if(res === true) {
            alert('Password Updated');
          }
          else {
              this.errors = res;
          }

        })
        .catch(err => console.log(err))
      }
    }
  }
</script>
