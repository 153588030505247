<template>
  <div style="text-align:center">
    <div style="display:inline-block" class="p-5">
      <div class="" style="min-width:40vw">
        <h2>Admin Users</h2>
        <div class="text-right">
          <router-link :to="$route.path + '/new'" class="btn btn-success ml-auto text-white"><i class="fa fa-plus fa-sm"></i> New</router-link>
          <!-- <export-button v-if="this.enableExport" :fields="fields" :records="items"></export-button> -->
          <!-- <button class="btn btn-dark" @click="addSearchFilter"><i class="fa fa-search fa-sm"></i> Search</button> -->
        </div>
      </div>
      <!-- <div class="">
        <div class="ml-auto  mr-auto">
          <complex-search :fields="fields" v-model="searchFilters" @input="fetchItems"></complex-search>
        </div>
      </div> -->
      <div class="">
        <div v-for="(row,rowkey) in items" :key="row.id" class="card card-body">
            <div class="text-left">
              <h2 style="display:inline">{{row.first_name ? (row.first_name + " " + row.last_name) : "" }}</h2>
              <div style="float:right">
                <span v-if="row.is_active==0" @click="activate(row.id)" class="pill" title="activate">
                  <i class="fa fa-toggle-on fa-fw text-secondary"></i>
                  <!-- <span class="ttip">activate</span> -->
                </span>  <!-- has to be router-link so keep-alive works -->
                <span v-else @click="deActivate(row.id)" class="pill" title="de-activate">
                  <i class="fa fa-toggle-off fa-fw text-success"></i>
                  <!-- <span class="ttip">de-activate</span> -->
                </span>  <!-- has to be router-link so keep-alive works -->
                <span @click="unlock(row.id)" class="pill" title="unlock">
                  <i v-if="row.is_locked==0" class="fa fa-key fa-fw text-secondary"></i>
                  <i v-else class="fa fa-key fa-fw " style="color:red"></i>
                  <!-- <span class="ttip">unlock</span> -->
                </span>  <!-- has to be router-link so keep-alive works -->
                <router-link :to="'/users/edit/' + row.id" class="pill" title="edit">
                  <i class="fa fa-pencil-alt fa-fw text-secondary"></i>
                  <!-- <span class="ttip">edit</span> -->
                </router-link>  <!-- has to be router-link so keep-alive works -->
                <router-link :to="'/users/' + row.id + '/roles'" class="pill" title="roles">
                  <i class="fa fa-user-astronaut fa-fw text-secondary"></i>
                  <!-- <span class="ttip">roles</span> -->
                </router-link>  <!-- has to be router-link so keep-alive works -->

              </div>
            </div>
            <div class="text-left"><h4>{{row.company ? row.company.name : ""}}</h4></div>

            <div style="margin-bottom: 20px">
              <!-- <div class="group-vertical"><span class="label">id</span><span class="value">{{row.id || "&nbsp;" }}</span></div> -->
              <div class="group-vertical"><span class="label">username</span><span class="value">{{row.username || "&nbsp;" }}</span></div>
              <div class="group-vertical"><span class="label">email</span><span class="value">{{row.email || "&nbsp;" }}</span></div>
              <!-- <div class="group-vertical"><span class="label">first_name</span><span class="value">{{row.first_name || "&nbsp;" }}</span></div> -->
              <!-- <div class="group-vertical"><span class="label">last_name</span><span class="value">{{row.last_name || "&nbsp;" }}</span></div> -->
              <!-- <div class="group-vertical"><span class="label">phone</span><span class="value">{{row.phone || "&nbsp;" }}</span></div> -->
              <!-- <div class="group-vertical"><span class="label">cellphone</span><span class="value">{{row.cellphone || "&nbsp;" }}</span></div> -->
              <!-- <div class="group-vertical"><span class="label">aim</span><span class="value">{{row.aim || "&nbsp;" }}</span></div> -->
              <!-- <div class="group-vertical"><span class="label">timezone</span><span class="value">{{row.timezone || "&nbsp;" }}</span></div> -->
              <div class="group-vertical"><span class="label">app_version</span><span class="value">{{row.app_version || "&nbsp;" }}</span></div>
              <div class="group-vertical"><span class="label">is_active</span><span class="value" v-if="row.is_active">yes</span><span class="value" v-else>no</span></div>
              <div class="group-vertical"><span class="label">is_locked</span><span class="value" v-if="row.is_locked">yes</span><span class="value" v-else>no</span></div>
              <div class="group-vertical"><span class="label">created_at</span><span class="value">{{row.created_at || "&nbsp;" }}</span></div>
              <div class="group-vertical"><span class="label">updated_at</span><span class="value">{{row.updated_at || "&nbsp;" }}</span></div>
              <div class="group-vertical"><span class="label">api_token</span><span class="value">{{row.api_token || "&nbsp;" }}</span></div>
            </div>

           <div v-if="items.length==0"><td colspan="42">No records found. </span></div>

        </div>
      </div>
      <div class="row">
        <div class="ml-auto mt-3">
          <ul class="pagination" v-if="pagination.total_pages">
            <li v-bind:class="[{disabled: !pagination.previous_page}]" class="page-item"><a class="page-link" href="#" @click="fetchPage(pagination.previous_page)">Previous</a></li>
            <li class="page-item disabled"><a class="page-link text-dark" href="#">Page {{ pagination.current_page }} of {{ pagination.total_pages }}</a></li>
            <li v-bind:class="[{disabled: !pagination.next_page}]" class="page-item"><a class="page-link" href="#" @click="fetchPage(pagination.next_page)">Next</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {exportButton, vueGrid, complexSearch} from 'vue-components'
  import * as library from 'js-library'

  export default {
    props: ['search','autoload','endpoint'],
    data() {
      return {
        items: [],
        searchFilters: [],
        search_obj: {},
        fields: {},
        actions: [],
        pagination: {},
        enableExport: true,
        title: ""
      }
    },

    methods: {
      fetchVM() {
        fetch(this.endpoint,{
          method: 'OPTIONS',
          headers: {
            'Content-Type': 'application/json',
            'X-TOKEN': Auth.token()
          }
        })
          .then(res => this.checkResponse(res))
          .then(res => res.json())
          .then(res => {
            console.log(res);
            if(typeof(res.data) != 'undefined') {
              this.title = res.title;
              this.enableExport = res.enableExport;
              this.actions = res.actions;
              this.fields = res.fields;
              if(res.searchFilters) this.searchFilters = res.searchFilters;

              this.items = res.data;
              this.pagination = res.pagination;
            }
            else this.items = res;
            if(this.autoload !== false) this.fetchItems();
          })
          .catch(err => console.log(err));
      },
      fetchItems() {
        var page_url = this.endpoint + '?';
        console.log(page_url);
        fetch(page_url, {
          headers: {
            'Content-Type': 'application/json',
            'X-TOKEN': Auth.token()
          }
        })
          .then(res => this.checkResponse(res))
          .then(res => res.json())
          .then(res => {
            if(typeof(res.data) != 'undefined') {
              this.items = res.data;
              this.pagination = res.pagination;
            }
            else this.items = res;
            this.setDetails();
          })
          .catch(err => console.log(err));
      },
      fetchPage(page) {
        console.log('fetchpage: '+page);
        var search_obj = library.$copy(this.search_obj);
        search_obj['page'] = page;
        this.search_obj = search_obj;
        this.fetchItems();
      },
      sort(obj) {
        console.log('sort',obj);
        var search_obj = library.$copy(this.search_obj);
        search_obj['sortField'] = obj.sortField;
        search_obj['sortDir'] = obj.sortDir;
        this.search_obj = search_obj;
        this.fetchItems();
      },
      setDetails() {
        this.items.forEach(item => {
          item.details = false;
          item.editing = false;
        });
      },
      // isActive(id) {
      //   return this.items.filter(function(item){
      //     return item.id === id;
      //   })[0].active;
      // },
      // itemIndex(id) {
      //   return this.items.indexOf(
      //     this.items.filter(function(item){
      //       return item.id === id;
      //     })[0]
      //   )
      // },
      activate(id) {
        if(confirm('Are you sure?')) {
          fetch(this.endpoint + '/' + id, {
            method: 'post',
            body: JSON.stringify({ is_active: 1 }),
            headers: {
              'content-type': 'application/json',
              'X-TOKEN': Auth.token()
            }
          })
          .then(res => this.checkResponse(res))
          .then(res => res.json())
          .then(res => {
            alert('User Activated');
            this.items[this.recordIndex(id)] = res;
            this.$forceUpdate();
          })
          .catch(err => console.log(err))
        }
      },
      deActivate(id) {
        // console.log('item index:'+this.itemIndex(id));
        // console.log('item index:'+this.recordIndex(id));
        if(confirm('Are you sure?')) {
          fetch(this.endpoint + '/' + id, {
            method: 'post',
            body: JSON.stringify({ is_active: 0 }),
            headers: {
              'content-type': 'application/json',
              'X-TOKEN': Auth.token()
            }
          })
          .then(res => this.checkResponse(res))
          .then(res => res.json())
          .then(res => {
            this.items[this.recordIndex(id)] = res;
            this.$forceUpdate();
            alert('User De-activated');

          })
          .catch(err => console.log(err))
        }
      },
      unlock(id) {
        // console.log('item index:'+this.itemIndex(id));
        // console.log('item index:'+this.recordIndex(id));
        if(confirm('Unlock user?')) {
          fetch(this.endpoint + '/' + id + '/unlock', {
            headers: {
              'content-type': 'application/json',
              'X-TOKEN': Auth.token()
            }
          })
          .then(res => this.checkResponse(res))
          .then(res => res.json())
          .then(res => {
            this.items[this.recordIndex(id)] = res;
            this.$forceUpdate();
            alert('User Unlocked');

          })
          .catch(err => console.log(err))
        }
      },
      deleteItem(id) {
        if(confirm('Are you Sure?')) {
          fetch(this.endpoint + '/' + id, {
            method: 'delete',
            headers: {
              'Content-Type': 'application/json',
              'X-TOKEN': Auth.token()
            }
          })
          .then(res => this.checkResponse(res))
          .then(res => res.json())
          .then( data => {
            alert('Item Removed');
            this.fetchItems();
          })
          .catch(err => console.log(err))
        }
      },
      details(index) {
        if(this.items[index].details) {
          this.items[index].details = false;
        }
        else {
          this.items[index].details = true;
        }
        this.$forceUpdate();
      },
      recordIndex(id) {
        let record = this.items.filter(function(record){ return record.id === id})[0];
        let index = this.items.indexOf(record);
        return index;
      },
      addSearchFilter: function() {
        this.searchFilters.push({field: '', op: '', value: ''});
      },
      removeFilter: function (index) {
        this.searchFilters.splice(index,1);
      }
    },
    computed: {

    },
    components: {
      complexSearch,
      vueGrid,
      exportButton
    },
    created() {
      this.fetchVM();
    },
    mounted() {
      if(this.search) this.searchFilters = this.search;
    }
  }
</script>

<style scoped>

.label {
  /* background: rgb(25, 150, 250, .1);
  border: 1px solid rgb(25, 150, 250, .2); */
  background: rgb(50, 125, 175, .1);
  border: 1px solid rgb(50, 125, 175, .2);

  display: inline;

  padding: 10px;
  border-radius: 6px;
  width: 100%;
}
.value {
  width: 100%;
  white-space: nowrap;
  display: inline;
  padding: 10px;
  text-align: center;
}
.group-vertical {
  /* width: 200px; */
  display: inline-block;

}
.group-vertical .label, .group-vertical .value {
  display: block;
}

.card {
  min-width: 80%;
  margin-top: 20px;
}

.pill {
  font-size: 1.3em;
  border-radius: 6px;
  padding: 10px;
  position: relative;
  display: inline-block;
  /* box-sizing: border-box; */
  border: 1px solid white;
}
.pill:hover {
  background: rgb(25, 150, 250, .1);
  border: 1px solid rgb(25, 150, 250, .2);
  cursor: pointer;
}

.pill .ttip {
  visibility: hidden;
  width: 120px;
  background: rgb(25, 150, 250, .1);

  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  position: absolute;
  z-index: 1;
  top: -30px;
  left: 105%;
}
.pill:hover .ttip {
  visibility: visible;
}

a {
  color: black;
}

</style>
