
<template>
    <div class="text-center">
      <div class="main">

        <div class="mt-5 mb-4">
          <h2>Logs</h2>
        </div>

        <div class="search">
          <div>
            <span class="label">Search: </span>
              <input name="search" v-model="search"></input>
          </div>
          <div>
            <span class="label">Level:</span>
            <select name="level" v-model="level">
              <option value=""></option>
              <option value="DEBUG">DEBUG</option>
              <option value="INFO">INFO</option>
              <option value="NOTICE">NOTICE</option>
              <option value="WARNING">WARNING</option>
              <option value="ERROR">ERROR</option>
              <option value="CRITICAL">CRITICAL</option>
              <option value="ALERT">ALERT</option>
              <option value="EMERGENCY">EMERGENCY</option>
            </select>
          </div>
          <div>
            <span class="label">File:</span>
            <select name="file" v-model="file">
              <option v-for="f in files" :value="f">{{ f }}</option>
            </select>
          </div>
          <div>
            <span class="label"></span>
            <input type="submit" value="Search" class="btn btn-primary" @click="fetchLogs"></input>
          </div>
        </div>
        <div class="mt-3">
          <h4 class="text-secondary">{{ rows.length }} rows</h4>
        </div>

        <table class="table-striped" v-if="rows.length">
            <thead>
              <tr>
                <th class="secondary">Channel</th>
                <th class="secondary">Timestamp</th>
                <th class="secondary">Level</th>
                <th class="secondary">Level</th>
                <th class="secondary">Message</th>
                <th class="secondary"></th>
              </tr>
            </thead>
            <tbody>

              <tr v-for="row,idx in rows">
                <td>{{ row.channel }}</td>
                <td v-if="row.datetime">{{ row.datetime.date }}</td>
                <td>{{ row.level }}</td>
                <td>{{ row.level_name }}</td>
                <td style="text-align:left;max-width:50vw;overflow:hidden;text-overflow:ellipsis;">{{ row.message }}</td>
                <!-- <td><a :href="loglink(row.id)">show</a></td> -->
                <td><a href="" @click="loglink(idx)">show</a></td>
              </tr>

            </tbody>
        </table>

      </div>
    </div>
</template>

<script>
import * as library from 'js-library'

export default {
  props: ['logo','size', 'margin', 'endpoint'],
  name: 'login',
  data: function() {
    return {
      rows: [],
      search: '',
      level: '',
      file: '',
      files: []
    }
  },
  computed: {
    query() {
      var output = "?";
      if(this.search.length) output += 'search='+this.search+'&';
      if(this.level.length) output += 'level='+this.level+'&';
      if(this.file.length) output += 'file='+this.file;
      if(output.length>1) return output;
      else return "";
    }
  },
  methods: {
    fetchLogs() {
      fetch(this.endpoint + this.query, {
        headers: {
          'Content-Type': 'application/json',
          'X-TOKEN': Auth.token()
        }
      })
      .then(res => checkResponse(res))
      .then(res => res.json())
      .then(res => {
        console.log(res);
        this.files = res.files;
        this.rows = res.rows;

        if(!this.file) {
          if(this.$route.query.file) {
            this.file = this.$route.query.file;
          }
          else {
            this.file = res.file;
          }
        }


      })
      .catch(err => console.log(err))
    },
    loglink(index) {
      event.preventDefault();
      console.log("index is: " + index);
      console.log(this.rows[index]);
      this.$router.push({
        // path: 'logs/' + this.rows[index].id,
        name: 'showLog',
        params: {
          log: this.rows[index],
          id: this.rows[index].id
        }
      })
    }
  },
  created: function() {

    // console.log(this.$route.query.to);
    // console.log("endpoint: " + this.endpoint);
    this.fetchLogs();
  },
  components: {

  }
}
</script>

<style scoped>
.search {
  display: inline-block;
  text-align: center;
  margin: 10px;
  /* border: 1px solid #ccc; */
  background: #f9f9fc;
  border-radius: 6px;
  padding: 25px;

}
.search div {
  margin: 6px;
}
.search .label {
  width: 60px;
  display: inline-block;
  text-align: right;
  /* border: 1px solid red; */
}
input, select {
  width: 300px;
  padding: 5px;
}
td {
  padding: 5px;
}
.main {
  display: inline-block;
  max-width: 80vw;
}
th {
  color: white;
  background: #475869;
  padding: 10px;
  font-weight: normal;
  position: sticky;
  top: 46px;
}
</style>
