
<template>
    <div>
      <div class="container p-1">
        <div class="row mt-5 mb-4">
          <h2>{{ $route.meta.title || "User Roles" }}</h2>
          <div class="ml-auto"><router-link to="/users" class="ml-auto btn btn-primary"><i class="fa fa-arrow-left"></i> Users</router-link></div>
        </div>
        <!-- <h2 class="mt-5 mb-4">{{ $route.meta.title || "User Roles" }}</h2> -->

        <!-- begin table -->
        <div class="row mb-4">
          <div class="card m-auto">
            <table class="table">
              <thead>
                <tr>
                  <th>Username</th><th>First Name</th><th>Last Name</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ attributes.username }}</td><td>{{ attributes.first_name }}</td><td>{{ attributes.last_name }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- end table -->

        <div class="row m-auto" style="display:inline-block">
          <div class="card card-body bg-light m-auto" style="display:inline-block">

            <div v-for="role in roles" :key="role.id" class="form-group">
                <input type="checkbox" :id="role.id" :name="role.name" :value="role.id" v-model="selectedRoles"></input><label :for="role.id" class="ml-2">{{ role.name }}</label>
            </div>

          </div>
        </div>
        <div class="text-center mt-4">
          <button @click="save" class="btn btn-success btn-lg ml-auto mr-4"><i class="fa fa-check"></i> Save</button>
          <button @click="$router.go(-1)" class="btn btn-secondary btn-lg mr-auto"><i class="fa fa-times"></i> Cancel</button>
        </div>
      </div>
    </div>
</template>

<script>

import * as library from 'js-library'

export default {
  props: ['endpoints'],
  name: 'roles',
  data: function() {
    return {
      attributes: {
        id: null,
        username: null,
        first_name: null,
        last_name: null,
        roles: []
      },
      roles: [],
      selectedRoles: []
    }
  },
  computed: {
    id() {
      return this.$route.params.id || this.attributes.id;
    }
  },
  methods: {
    fetchUser(id) {
      fetch(this.endpoints.users + '/' + id + '/roles', {
        headers: {
          'Content-Type': 'application/json',
          'X-TOKEN': Auth.token()
        }
      })
      .then(res => this.checkResponse(res))
      .then(res => res.json())
      .then(res => {
        this.attributes = res.user;
        this.roles = res.roles;
        this.setSelectedRoles();
        // this.fetchRoles();
      })
      .catch(err => console.log(err));
    },
    // fetchRoles() {
    //   fetch(this.endpoints.roles)
    //   .then(res => this.checkResponse(res))
    //   .then(res => res.json())
    //   .then(res => {
    //     this.roles = res;
    //     this.setSelectedRoles();
    //   })
    //   .catch(err => console.log(err));
    // },
    save() {
      fetch(this.endpoints.users + '/' + this.id,{
        method: 'post',
        body: JSON.stringify({
          "roles" : this.selectedRoles
        }),
        headers: {
          'content-type': 'application/json',
          // 'X-CSRF-TOKEN': window.csrfToken
          'X-TOKEN': Auth.token()
        }
      })
      .then(res => this.checkResponse(res))
      .then(res => res.json())
      .then( data => {
        console.log(data);
        alert('Roles Saved');
        // this.fetchUser();  // user not returned properly because viewmodel vs model

      })
      .catch(err => console.log(err))
    },
    setSelectedRoles() {
      this.selectedRoles = this.attributes.roles.map(function(role){ return role.id });
    }
  },
  created: function() {
    console.log("time to manage roles");
    this.fetchUser(this.id);
  },
  watch: {

  },
  components: {
    //fCard
  }
}
</script>

<style scoped>
.card table th, .card table td {
  border-top: none;
}
.row {
  width: 100%;
  text-align: center;
}
</style>
