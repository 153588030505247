
<template>
  <nav class="no-print">
    <ul>
      <li class="hamburger"><a href="/">&#9776;</a></li>
      <li><a href="#">Search</a>
        <ul>
          <li><router-link to="/notices">Notices</router-link></li>

          <li v-if="Auth.is('Admin')"><router-link to="/pipelines">Pipelines</router-link></li>
          <li v-if="Auth.is('Admin')"><router-link to="/locations">Locations</router-link></li>
          <li v-if="Auth.is('Admin')"><router-link to="/zones">Zones</router-link></li>
          <li v-if="Auth.is('Admin')"><router-link to="/shippers">Shippers</router-link></li>
          <li v-if="Auth.is('Admin')"><router-link to="/endUsers">EndUsers</router-link></li>
          <li v-if="Auth.is('Admin')"><router-link to="/capacityRelease">Capacity Release</router-link></li>
          <li v-if="Auth.is('Admin')"><router-link to="/contracts">Contracts</router-link></li>
          <li v-if="Auth.is('Admin')"><router-link to="/rateschedules">Rate Schedules</router-link></li>
        </ul>
      </li>
      <!-- <li><a href="#">File Upload</a>
        <ul>
          <li><a href="/uploadFile">Upload File</a></li>
          <li><a href="/uploads">File Uploads</a></li>
          <li><a href="/imports">File Imports</a></li>
        </ul>
      </li> -->
      <!-- <li><a href="#">Reports</a>
        <ul>
          <li><a href="/endUserLocations">End User Locations</a></li>
          <li><a href="/shipperAgencies">Shipper Agencies</a></li>
          <li><a href="/enduserAgencies">Capacity Holder Agencies</a></li>
          <li><a href="/biddableReleaseBuyers">Location Detail View</a></li>
          <li><a href="/newestLocations">Newest Locations</a></li>
        </ul>
      </li> -->
      <li><a href="#">User</a>
        <ul>
          <li><a href="/logout">Logout</a></li>
          <li><a href="/updateUser">Update Info</a></li>
          <li><a href="/changePassword">Change Password</a></li>
          <!-- <li><a href="/subscriptions">Manage Subscriptions</a></li> -->
          <!-- <li><a href="/privacystatement">View Privacy Statement</a></li> -->
          <!-- <li><a href="/tos">View Terms of Service</a></li> -->
        </ul>
      </li>

      <li v-if="Auth.is('Admin')"><a href="#">Admin</a>
        <ul>
          <li><a href="/users">Users</a></li>
          <li><a href="/uploads">Uploads</a></li>
          <li><a href="/imports">Imports</a></li>
          <li><a href="/logs">Logs</a></li>
          <li><a href="/queue">Queue</a></li>
          <li><a href="/queuesummary">Queue Summary</a></li>
        </ul>
      </li>

    </ul>
  </nav>
</template>

<script>
  export default {

  }
</script>

<style scoped>
@media print
{
    .no-print, .no-print *
    {
        display: none !important;
    }
}
@media screen and (min-width: 600px) {
	nav, nav ul, nav ul li, nav ul li a, nav ul ul, nav ul ul li, nav ul ul li a, nav ul ul ul {
	  box-sizing: border-box;
	  margin: 0;
	  z-index: 125;
	  cursor: pointer;
    text-align: left;
    font-family: "Tahoma";
    color: #444;
    /* color: red; */
    line-height: 1.5;  /* this fixes the menu spacing */
	}

	nav {
	  position: fixed;
	  top: 0;
	  left: 0;
	  width: 100%;
	  background: #fff;
	  border: 1px solid #dae0e5;
	  display: block;
	}

	nav ul {
	  list-style: none;
	  position: relative;
	  float: left;
	  display: inline-table;
	  padding: 0;
	  margin: 0;
	}

	nav ul li {
	  display: block;
	  /* padding: 10px 40px; */
	  /* color: #212529; */
	  /* letter-spacing: 1px; */
	  text-decoration: none;
	}


	nav ul li:hover {
	  /* background: rgba(0, 0, 0, 0.15); */
    background: #e2e6ea;
	}

	nav ul li:hover > ul {
	  display: block;
	}

	nav ul li {
	  float: left;
	  -webkit-transition: all .25s ease-in-out;
	  transition: all .25s ease-in-out;
	}

	nav ul li a {
	  display: block;
	  /* color: #212529; */
	  /* font-size: small; */
	  /* letter-spacing: 1px; */
	  text-decoration: none;
    padding: 10px 40px;
	}
  nav ul li a:hover {
    /* position: relative;
    right: 10px; */
	  /* color: #212529; */
	  text-decoration: none;
    /* font-weight: bolder; */
	}

	nav ul ul {
	  display: none;
	  background: #fff;
	  position: absolute;
	  top: 100%;
	  box-shadow: -3px 3px 10px -2px rgba(0, 0, 0, 0.1);
	  border: 1px solid #dae0e5;
	  white-space: nowrap;
	  margin: 0;
	}

	nav ul ul li {
	  float: none;
	  position: relative;
	  margin: 0;
	}

	nav ul ul li a {
	  white-space: nowrap;
	  margin: 0;
	}

	nav ul ul ul {
	  position: absolute;
	  left: 100%;
	  top: 0;
	}

  .unfinished a {
    /* background-color: #cde; */
    color: #ccc;
  }

  /* .hamburger {
    font-size: 90%;
  } */
}
</style>
