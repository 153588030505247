
<template>
    <div class="text-center p-5">
      <div class="main">

        <div class="mt-2 mb-4">
          <h2>Queue</h2>
        </div>

        <div class="mb-4">
          <h4 class="text-secondary">{{ rows.length }} rows</h4>
        </div>

        <table class="table-striped" v-if="rows.length">
            <thead>
              <tr>
                <th class="secondary">id</th>
                <th class="secondary">description</th>
                <th class="secondary">job</th>
                <th class="secondary">ref</th>
                <th class="secondary">payload</th>
                <th class="secondary">delay</th>
                <th class="secondary">created_by</th>
                <th class="secondary">created_at</th>
                <th class="secondary">updated_at</th>
                <th class="secondary">started</th>
                <th class="secondary">completed</th>
                <th class="secondary">failed</th>
                <th class="secondary">time</th>
                <th class="secondary">exit_code</th>
                <th class="secondary">pid</th>
                <th class="secondary">comment</th>
                <th class="secondary">result</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="row in rows">
                <td>{{ row.id }}</td>
                <td>{{ row.description }}</td>
                <td>{{ row.job }}</td>
                <td>{{ row.ref }}</td>
                <td>{{ row.payload }}</td>
                <td>{{ row.delay }}</td>
                <td>{{ row.created_by }}</td>
                <td>{{ row.created_at }}</td>
                <td>{{ row.updated_at }}</td>
                <td>{{ row.started }}</td>
                <td>{{ row.completed }}</td>
                <td>{{ row.failed }}</td>
                <td>{{ row.time }}</td>
                <td>{{ row.exit_code }}</td>
                <td>{{ row.pid }}</td>
                <td class="comment">{{ row.comment }}</td>
                <td>{{ row.result }}</td>
              </tr>
            </tbody>
        </table>

        <div class="row">
            <ul class="pagination mx-auto text-center mt-3" v-if="pagination.last_page > pagination.current_page">
              <li v-bind:class="[{disabled: !pagination.last_page}]" class="page-item"><a class="page-link" href="#" @click="fetchPage(pagination.last_page)">Previous</a></li>
              <li class="page-item disabled"><a class="page-link text-dark" href="#">Page {{ pagination.current_page }} of {{ pagination.total_count }}</a></li>
              <li v-bind:class="[{disabled: !pagination.next_page}]" class="page-item"><a class="page-link" href="#" @click="fetchPage(pagination.next_page)">Next</a></li>
            </ul>
        </div>

      </div>
    </div>
</template>

<script>
import * as library from 'js-library'

export default {
  props: ['endpoint'],
  name: 'queue',
  data: function() {
    return {
      rows: [],
      pagination: {}
    }
  },
  methods: {
    fetchItems(query_string='') {
      fetch(this.endpoint + query_string, {
        headers: {
          'Content-Type': 'application/json',
          'X-TOKEN': Auth.token()
        }
      })
      .then(res => checkResponse(res))
      .then(res => res.json())
      .then(res => {
        console.log(res);
        this.pagination = res.jobs.pagination;
        this.rows = res.jobs.data;

      })
      .catch(err => console.log(err))
    },
    fetchPage(page) {
      console.log('fetchpage: '+page);
      this.fetchItems('?page='+page);
    },
  },
  created: function() {
    console.log("endpoint: " + this.endpoint);
    this.fetchItems();
    console.log(this.$route.query)
  },
  components: {

  }
}
</script>

<style scoped>
.search {
  display: inline-block;
  text-align: center;
  margin: 10px;
  /* border: 1px solid #ccc; */
  background: #f9f9fc;
  border-radius: 6px;
  padding: 25px;

}
.search div {
  margin: 6px;
}
.search .label {
  width: 60px;
  display: inline-block;
  text-align: right;
  /* border: 1px solid red; */
}
input, select {
  width: 300px;
  padding: 5px;
}
td {
  padding: 5px;

}
td.comment {
  width: 100px;
  overflow:hidden;
}
.main {
  display: inline-block;
  /* max-width: 80vw; */
}
th {
  color: white;
  background: #475869;
  padding: 10px;
  font-weight: normal;
  position: sticky;
  top: 46px;
}
</style>
