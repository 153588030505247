
<!-- this view is the same as editUser but intended for the user not the admin -->
<template>
    <div v-show="loaded" class="bg-light" style="min-height: 100vh">
      <!-- <drop-menu on="notFound"></drop-menu> -->
      <div class="container p-1 text-center">
        <div style="font-family: Arial; font-size:4em; color: #669bc4; margin-top: 30vh">
          404 Not Found
        </div>

        <div style="font-family: Arial; font-size:2.5em; color: #43607c; margin-top: 7vh">
          The requested URL was not found on this server.
        </div>

        <!-- <div style="font-family: Arial; font-size:2.5em; margin-top: 100px">
          <a href="/" style="text-decoration: none; background-color: #5dc1b7; color: white; padding: 16px 32px; border-radius: 8px">Return to FlowNotices</a>
        </div> -->

      </div>
    </div>
</template>

<script>
  // import dropMenu from '../components/drop-menu.vue'
  export default {
    props: ['title'],
    data() {
      return {
        loaded: false,
        attributes: {

        },
      }
    },
    mounted() {
      this.loaded = true;
    },
    methods: {

    },
    components: {
      // dropMenu
    }
  }
</script>

<style scoped>

</style>
