
<template>
    <div v-show="loaded" class="bg-light" style="min-height: 100vh">
      <div class="container p-1 text-left pt-5">

        <div class="breadcrumb"><router-link to="/notices" class="ml-auto btn btn-primary"><i class="fa fa-arrow-left"></i> Notices</router-link></div>

        <!-- <drop-menu on=""></drop-menu> -->

        <h3 class="mt-5 mb-4">{{ attributes.subject || "View Notice Detail" }}</h3>

        <table class="table table-bordered bg-white">
          <tr v-show="attributes.pipeline.name !== null">
            <th>Pipeline</th><td>{{ attributes.pipeline.name }}</td>
          </tr>
          <tr v-show="attributes.critical !== null">
            <th>Critical</th><td>{{ yesNo(attributes.critical) }}</td>
          </tr>
          <tr v-show="attributes.notice_type !== null">
            <th>Type</th><td>{{ attributes.notice_type }}</td>
          </tr>
          <tr v-show="attributes.subject !== null">
            <th>Subject</th><td>{{ attributes.subject }}</td>
          </tr>
          <tr v-show="attributes.notice_identifier !== null">
            <th>Notice Id</th><td>{{ attributes.notice_identifier }}</td>
          </tr>
          <tr v-show="attributes.author !== null">
            <th>Author</th><td>{{ attributes.author }}</td>
          </tr>
          <!-- <tr v-show="attributes.notice_text !== null">
            <th>Notice Text</th><td>{{ attributes.notice_text }}</td>
          </tr> -->
          <tr v-show="attributes.download_link !== null">
            <th>Download Link</th><td>{{ attributes.download_link }}</td>
          </tr>
          <tr v-show="attributes.prior_notice !== null">
            <th>Prior Notice</th><td>{{ attributes.prior_notice == 1 ? 'Yes' : 'No' }}</td>
          </tr>
          <tr v-show="attributes.status !== null">
            <th>Status</th><td>{{ attributes.status }}</td>
          </tr>
          <tr v-show="attributes.area !== null">
            <th>Area</th><td>{{ attributes.area }}</td>
          </tr>
          <tr v-show="attributes.posted_datetime !== null">
            <th>Posted Date</th><td>{{ attributes.posted_datetime}}</td>
          </tr>
          <tr v-show="attributes.effective_datetime !== null">
            <th>Eff Date</th><td>{{ attributes.effective_datetime }}</td>
          </tr>
          <tr v-show="attributes.ends_datetime !== null">
            <th>End Date</th><td>{{ attributes.ends_datetime }}</td>
          </tr>
          <tr v-show="attributes.response_datetime !== null">
            <th>Resp Date</th><td>{{ attributes.response_datetime }}</td>
          </tr>
          <tr v-show="attributes.cycle !== null">
            <th>Cycle</th><td>{{ attributes.cycle }}</td>
          </tr>
          <tr v-show="attributes.location_name !== null">
            <th>Loc Name</th><td>{{ attributes.location_name }}</td>
          </tr>
          <tr v-show="attributes.location_number !== null">
            <th>Loc No</th><td>{{ attributes.location_number }}</td>
          </tr>
          <!-- <tr>
            <th>Notice Text</th><td><pre>{{ attributes.notice_text }}</pre></td>
          </tr> -->
        </table>

        <div class="mt-5">
          <!-- <strong>Notice Text:</strong> -->
          <div class="notice-text" v-html="attributes.notice_text"></div>
          <!-- <div class="notice-text">{{ attributes.notice_text }}</div> -->



      </div>
    </div>
</template>

<script>

import * as library from 'js-library'
// import dropMenu from '../components/drop-menu.vue'

export default {
  name: 'noticeDetail',
  props: {
    endpoint : {
      default: function() {
        return null;
      }
    },
    title : {
      default: function() {
        return null;
      }
    }
  },
  data: function() {
    return {
      loaded: false,
      attributes: {
        id: null,
        pipeline: {},
        critical: null,
        notice_type: null,
        subject: null,
        notice_identifier: null,
        author: null,
        notice_text: null,
        download_link: null,
        prior_notice: null,
        status: null,
        area: null,
        posted_datetime: null,
        effective_datetime: null,
        ends_datetime: null,
        response_datetime: null,
        cycle: null,
        location_name: null,
        location_number: null
      },
      loading: true
    }
  },
  computed: {
    id() {
      return this.$route.params.id || this.attributes.id;
    },
  },
  methods: {
    fetchItem() {
      return new Promise(function(resolve,reject) {
        fetch(this.endpoint + '/' + this.$route.params.id, {
              headers: {
                'Content-Type': 'application/json',
                'X-TOKEN': Auth.token()
              }
            })
          .then(res => res.json())
          .then(res => {
            for(var item in res) {
              this.attributes[item] = res[item];
            }
            this.loading = false;
            this.loaded = true;
            resolve(res);
          })
          .catch(err => {
            console.log(err);
            reject(err);
          });
        }.bind(this));
    },
    yesNo(val) {
      if(val) return "Yes";
      return "No";
    }
  },
  mounted: function() {
    // console.log("wa da tay");
    this.fetchItem();
  },
  watch: {
    '$route': function(to, from) {
      //console.log("endpoint change",to,from);
      if(to.name === 'noticeDetail') {
        this.loaded = false;
        this.loading = true;
        this.fetchItem();
        window.scrollTo(0,0);
      }
    }
  },
  components: {
    // dropMenu
  }
}
</script>

<style scoped>
.card table th, .card table td {
  border-top: none;
}
.row {
  width: 100%;
  text-align: center;
}
.notice-text {
  /* white-space: normal; */
  white-space: pre-line;
  font-size: 1.1em;
  font-family: "Arial";
  margin-bottom: 20vh;  /* allows you to scroll it to the middle of the page */
  margin-top: 10px;
}

.noticetext {
  white-space: pre-wrap;  /* this preserves line breaks in the posting */
}

.breadcrumb {
  position: absolute;
  top: .8em;;
  left: 20px;
  padding: 0;
}

.table {
  font-size: .8em;
}

table tr th {
  min-width: 10em;
}


</style>
