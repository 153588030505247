
<template>

    <div class="container-fluid text-center" style="width:100%">

      <div class="row" >
        <div class="col-6 text-left p-4"><h3>{{ $route.meta.title || title }}</h3></div>
        <div class="ml-auto col-6 text-right p-4">
          <router-link v-if="this.showNewButton" :to="$route.path + '/new'" class="btn btn-success ml-auto"><i class="fa fa-plus fa-sm"></i> New</router-link>
          <export-button v-if="this.showExportButton" :fields="fields" :records="items"></export-button>
          <button class="btn btn-dark" @click="searchOpen=!searchOpen"><i class="fa fa-search fa-sm"></i> Search Filters</button>
        </div>
      </div>

      <!-- <div class="row">
        <div class="text-center col-12" >
          <complex-search :fields="fields" v-model="searchFilters" @input="fetchItems"></complex-search>
        </div>
      </div> -->

      <div class="grid-window">
        <fractions-grid v-show="recordsFormatted.length && !loading" class="mx-auto" row-click :fields="fields" :records="recordsFormatted" :actions="actions" :totals="totals" @action="handleAction" @sort="sort"></fractions-grid>

      </div>
      <div class="row">
        <div class="ml-auto mt-3 mr-4">
          <ul class="pagination" v-if="pagination.last_page">
            <li v-bind:class="[{disabled: pagination.current_page==1}]" class="page-item"><a class="page-link" href="#" @click="fetchPage(pagination.previous_page)">Previous</a></li>
            <li class="page-item disabled"><a class="page-link text-dark" href="#">Page {{ pagination.current_page }} of {{ pagination.last_page }}</a></li>
            <li v-bind:class="[{disabled: !pagination.next_page}]" class="page-item"><a class="page-link" href="#" @click="fetchPage(pagination.next_page)">Next</a></li>
          </ul>
        </div>
      </div>

      <!-- <vue-modal v-if="searchOpen" @close="searchOpen=false">
          <complex-search :fields="fields" v-model="searchFilters" @input="fetchItems"></complex-search>
      </vue-modal> -->

      <!-- Small modal -->
      <div v-show="searchOpen" >
        <div class="overlay"></div>
          <div class="modal fade show"  @click="searchOpen=false" style="display:block;padding-right:17px;" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document" @click.stop="">
              <div class="modal-content">

                <div class="modal-body">
                  <button type="button" class="close" style="outline:none;font-size:2em;position:absolute;right:1em;top:5px;" aria-label="AddSearchFilter" @click="addSearchFilter">
                    <span>+</span>
                  </button>
                  <button type="button" class="close mr-auto" style="outline:none;font-size:2em;position:absolute;right:10px;top:5px;" aria-label="Close" @click="searchOpen=false">
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <complex-search :fields="fields" v-model="searchFilters"></complex-search>
                  <button type="button" class="btn btn-primary" aria-label="Search" @click="fetchItems">
                    <span>Search</span>
                  </button>
                </div>

              </div>
            </div>
          </div>
        </div>
        <pulse-loader :loading="loading" color="#7799cc" style="position:fixed;top:45%;left:50%;z-index:1000;margin:10px;"></pulse-loader>
      </div>

</template>

<script>
  // import {vueGrid as fractionsGrid, complexSearch, exportButton, vueModal} from 'vue-components'
  import fractionsGrid from 'vue-grid'
  import complexSearch from 'vue-complex-search'
  import exportButton from 'vue-export-button'
  import vueModal from 'vue-modal'
  import * as library from 'js-library'
  import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

  export default {
    name: 'gridView',
    props: {
      endpoint : {
        default: function() {
          return null;
        }
      },
      title : {
        default: function() {
          return null;
        }
      },
      search : {
        default: function() {
          //return [{field: '', op: '', value: ''}]
          return null;
        }
      },
      autoload : {
        default: true
      }},
    data() {
      return {
        items: [],
        searchFilters: [{field: '', op: '', value: ''}],
        search_obj: {},
        fields: {},
        commands: [],
        actions: [],
        pagination: {},
        enableExport: false,
        searchOpen: false,
        loading: false,
        // totals: []
      }
    },
    methods: {
      fetchVM() {
        console.log(this.endpoint);
        fetch(this.endpoint,{
            method: 'OPTIONS',
            headers: {
              'Content-Type': 'application/json',
              'X-TOKEN': Auth.token()
            }
          })
          .then(res => this.checkResponse(res))
          .then(res => res.json())
          .then(res => {

            var to_get = ['form_data','search_data','showTotals','actions','enableExport','commands','tabs','fields','autoload'];

            for(var i in to_get) {
              var item = to_get[i];
              console.log(item, res[item]);
              if(typeof res[item] !== 'undefined') this[item] = res[item];
            }

            if(this.autoload !== false) this.fetchItems();
          })
          .catch(err => console.log(err));
      },
      fetchItems() {
        var page_url = this.endpoint + '?' + this.searchString;
        console.log(page_url);
        this.loading = true;
        fetch(page_url, {
          headers: {
            'Content-Type': 'application/json',
            'X-TOKEN': Auth.token()
          }
        })
          .then(res => this.checkResponse(res))
          .then(res => res.json())
          .then(res => {
            if(typeof(res.data) != 'undefined') {
              this.items = res.data;
              this.pagination = res.pagination;
            }
            else this.items = res;
            this.setDetails();
            this.updateQuery();  // updates the url bar
          })
          .catch(err => console.log(err));
      },
      fetchPage(page) {
        console.log('fetchpage: '+page);
        var search_obj = library.$copy(this.search_obj);
        search_obj['page'] = page;
        this.search_obj = search_obj;
        this.fetchItems();
      },
      sort(obj) {
        console.log('sort',obj);
        var search_obj = library.$copy(this.search_obj);
        // search_obj['sortField'] = obj.sortField;
        // search_obj['sortDir'] = obj.sortDir;
        search_obj['sort'] = obj.sortField + ':' + obj.sortDir;
        this.search_obj = search_obj;
        this.fetchItems();
      },
      setDetails() {
        this.items.forEach(item => {
          item.details = false;
          item.editing = false;
        });
      },
      handleAction(payload) {
        console.log('handleAction', payload);
        if(payload.action === '/') { // there is no verb
          this.$router.push(this.$route.path + payload.id );
        }
        if(payload.action.substring(0,1) === '/') { // new nomenclature for a relationship link
          this.$router.push(this.$route.path + '/' + payload.id + payload.action);
        }
        else if(payload.action == 'delete') {
          this.deleteItem(payload.id);
        }
        else if(payload.action == 'view') {
          this.$router.push(this.$route.path + '/' + payload.id);
        }
        else if(payload.action == 'copy') {
          this.$router.push(this.$route.path + '/new/' + payload.id);
        }
        else if(payload.action == 'edit') {
          this.$router.push(this.$route.path + '/edit/' + payload.id);
        }
        else if(payload.action == 'history') {
          this.$router.push(this.$route.path + '/history/' + payload.id);
        }
        else if(payload.action == 'import') {
          this.$router.push('/importFile/' + payload.id);
        }
        else if(payload.action == 'items') {
          console.log(payload);
          var record = this.items.filter(function(row){
            return row.id == payload.id;
          })[0];
          this.$router.push('/ratebuilder/' + record.rategroup.id);
        }
        else if(payload.action == 'download') {
          window.location = '/api/uploads/view/' + payload.id;
        }
      },
      deleteItem(id) {
        if(confirm('Are you Sure?')) {
          fetch(this.endpoint + '/' + id, {
            method: 'delete',
            headers: {
              'Content-Type': 'application/json',
              'X-TOKEN': Auth.token()
            }
          })
          .then(res => res.json())
          .then( data => {
            alert('Item Removed');
            this.fetchItems();
          })
          .catch(err => console.log(err))
        }
      },
      details(index) {
        if(this.items[index].details) {
          this.items[index].details = false;
        }
        else {
          this.items[index].details = true;
        }
        this.$forceUpdate();
      },
      recordIndex(id) {
        let record = this.records.filter(function(record){ return record.id === id})[0];
        let index = this.records.indexOf(record);
        return index;
      },
      addSearchFilter: function() {
        this.searchFilters.push({field: null, op: null, value: null});
      },
      removeFilter: function (index) {
        this.searchFilters.splice(index,1);
      },
      applyQuery() { // apply url search terms to complex search....booyah
        console.log(this.$route.query);
        var query = this.$route.query;
        var output = [];
        for(var key in query) {
          console.log("query key:",key);
          //if(!this.fields.hasOwnProperty(key)) continue;  // filters page, anything that doesn't belong
          //if(key == 'page') continue;
          var value = query[key];
          console.log("query value:",value,value.indexOf(":"));
          if(value.indexOf(":") !== -1) {
            console.log("sharttown");
            var operator = value.substr(0,value.indexOf(":"));
            value = value.substr(value.indexOf(":")+1);
            console.log("operator:",operator);
            console.log("value:",value);
          }
          else {
            var operator = "equals";
          }
          output.push({
            field: key,
            op: operator,
            value: value
          });
        }
        console.log("output length:",output.length);
        if(output.length > 0) this.searchFilters = output;
        else this.searchFilters = [{field: '', op: '', value: ''}];  // so we start with a blank
      },
      updateQuery(key, value) {
        this.$router.replace({
        	query: this.searchData
        })
      }
    },
    computed: {
      id() {
        //return this.$route.params.id || this.attributes.id;
        return this.$route.params.id || null;
      },
      page_url() {
        var page_url = '';
        var endpoint = this.endpoint;
        if(typeof(endpoint) == 'string') {
          page_url = endpoint;
          if(this.id !== null) page_url += '/' + this.id;
        }
        else if(typeof(endpoint) == 'object') {
          page_url = endpoint[0] + this.id + endpoint[1];
        }

        if(this.searchString.length>0) page_url += '?' + this.searchString;
        return page_url;
      },
      showNewButton() {
        for(var key in this.commands) {
          if(this.commands[key] === 'new') return true;
        }
        return false;
      },
      showExportButton() {
        return this.enableExport;
      },
      recordsFormatted() {
        var that = this;
        if(!this.items) return {};
        return this.items.map(function(row){
          var output = {};
          var result = null;
          for(var field in row) {
            if(field in that.fields && that.fields[field].format !== 'hide') {
              var data = row[field];
              //result = data;

              //if(typeof(field) == 'undefined') result = "";
              var format = that.fields[field].format;

              if(typeof(data) == 'undefined' || data === '') result = "";

              else if(format == 'list') {
                let list = that.fields[field].list;
                let obj = list.filter(function (record) { return +record.id === +data })[0];  /* data comes out of PDO as strings*/
                if(obj) result = obj['display'];  /*** was throwing error if data point was not in list..could happen if something is deleted ***/
                else result = '';  // so it shows blanks not 0's.
              }

              else result = library.$format(data, format);

              output[field] = result;
            }
            else if(['clicked', 'editing', 'saved', 'status'].includes(field)) output[field] = row[field];
          }
          return output;
        });
      },
      searchData() {  // creates a key-value object to send to store for searching
        var output = {};
        for(var f=0;f<this.searchFilters.length;f++) {
          var filter = this.searchFilters[f];
          if(filter.value.length<1) continue;
          if(filter.field==="") continue;

          if(filter.op === 'equals') {
            var new_value = filter.value;
          }
          else {
            var new_value = filter.op + ':' + filter.value;
          }

          if(output[filter.field] && Array.isArray(output[filter.field])) {
            // array time
            output[filter.field].push(new_value);
          }
          else if(output[filter.field]) {
            // new array time
            var tempval = output[filter.field];
            output[filter.field] = [tempval, new_value];
          }
          else output[filter.field] = new_value;
        }
        // the below is needed for pagination and sort
        for(var field in this.search_obj) {
          output[field] = this.search_obj[field];
        }
        return output;
      },
      // searchData() {  // creates a key-value object to send to store for searching
      //   var output = {};
      //   for(var f=0;f<this.searchFilters.length;f++) {
      //     var filter = this.searchFilters[f];
      //     if(filter.value.length<1) continue;
      //     if(filter.field==="") continue;
      //     var new_op = '';
      //     var new_value = filter.value;
      //     if(filter.op === '%3E') new_op = 'gt';
      //     if(filter.op === '%3C') new_op = 'lt';
      //     if(filter.op === '%3E%3D') new_op = 'gte';
      //     if(filter.op === '%3C%3D') new_op = 'lte';
      //     if(filter.op === 'contains') new_op = 'contains';
      //     if(new_op.length) new_value = new_op + ':' + new_value;
      //     if(output[filter.field]) {
      //       // array time
      //       var tempval = output[filter.field];
      //       output[filter.field] = [tempval, new_value];
      //     }
      //     else output[filter.field] = new_value;
      //     //output[filter.field] = filter.value;
      //     //output[filter.field+'-op'] = filter.op;
      //   }
      //   // the below is needed for pagination and sort
      //   for(var field in this.search_obj) {
      //     output[field] = this.search_obj[field];
      //   }
      //   return output;
      // },
      searchString() {
        var search_obj = this.searchData || {};
        console.log('search_obj',search_obj);
        var searchString = library.$objToHttp(search_obj);
        console.log('searchString',searchString);
        return searchString;
      },
      totals() {
        var totals = {};
        for(var field in this.fields) {
          if(this.fields[field].totals == true) {
            var fieldTotal = 0;
            for(var i=0;i<this.records.length;i++) {
              fieldTotal += Number(this.records[i][field]);
            }
            //totals[field] = library.$format(fieldTotal, field.format);
            totals[field] = fieldTotal;
          }
          else totals[field] = '';
        }
        return totals;
      }
    },
    components: {
      complexSearch,
      fractionsGrid,
      exportButton,
      vueModal,
      PulseLoader
    },
    created() {
      console.log("shartastical");
      console.log(this.fields);
      if(!this.fields.hasOwnProperty("id")) this.fetchVM();

      if(this.search) this.searchFilters = this.search;
      else this.applyQuery();

      this.loading = true;

      console.log("welcome to grid  view!!");
    },
    mounted() {

    },
    watch: {
      endpoint(to, from) {
        console.log("endpoint change",to,from);
        this.loading = true;
        this.fetchVM();
      },
      items(to, from) {
        this.loading = false;
      }
      // '$route' (to, from) {
      //   console.log("route change",to,from);
      //   this.fetchVM();
      // }
      // '$route.query' (query) {
      // 	//this.value = query[this.name] || ''
      //   for(var item in query) {
      //     console.log("query " + item + " is " + query[item]);
      //     //this.searchFilters.push({field: '', op: '', value: ''});
      //   }
      // }
    }
  }
</script>

<style scoped>

.redbox { border: 1px solid red;}
.grid-window {

  overflow: auto;
  max-height: 80vh;
  /* position:sticky;
  top:0; */
}
</style>
