
<!-- this view is the same as editUser but intended for the user not the admin -->
<template>
    <div>
      <div class="container p-1">
        <div class="row mt-5 mb-4">
          <h2>{{ $route.meta.title || "Edit User" }}</h2>
          <!-- <div class="ml-auto"><router-link to="/users" class="ml-auto btn btn-primary"><i class="fa fa-arrow-left"></i> Users</router-link></div> -->
        </div>
        <div class="card card-body bg-light mb-4">

          <div class="form-group">
            <label class="col-sm-2 col-form-label">First Name</label>
            <input type="text" class="form-control" v-model="attributes.first_name">
          </div>

          <div class="form-group">
            <label class="col-sm-2 col-form-label">Last Name</label>
            <input type="text" class="form-control" v-model="attributes.last_name">
          </div>

          <div class="form-group">
            <label class="col-sm-2 col-form-label">User Name</label>
            <input type="text" class="form-control" v-model="attributes.username">
          </div>

          <div class="form-group">
            <label class="col-sm-2 col-form-label">Phone</label>
            <input type="text" class="form-control" v-model="attributes.phone">
          </div>

          <div class="form-group">
            <label class="col-sm-2 col-form-label">Cell Phone</label>
            <input type="text" class="form-control" v-model="attributes.cellphone">
          </div>

          <div class="form-group">
            <label class="col-sm-2 col-form-label">AIM</label>
            <input type="text" class="form-control" v-model="attributes.aim">
          </div>

          <div class="form-group">
            <label class="col-sm-2 col-form-label">E-mail</label>
            <input type="text" class="form-control" v-model="attributes.email">
          </div>

          <!-- <div class="form-group">
            <label class="col-sm-2 col-form-label">Time Zone</label>
            <select class="form-control" v-model="attributes.pipeline">
              <option v-for="option in fields.pipeline.list" :value="option.id">{{ option.display }}</option>
            </select>
          </div> -->

        </div>

        <div class="text-center">
          <button @click="save" class="btn btn-success ml-auto mr-4"><i class="fa fa-check"></i> Save</button>
          <router-link to="." class="btn btn-secondary mr-auto"><i class="fa fa-times"></i> Cancel</router-link>
        </div>
      </div>
    </div>
</template>

<script>
  export default {
    props: ['endpoints'],
    data() {
      return {
        attributes: {
          id: '',
          username: '',
          company: '',
          first_name: '',
          last_name: '',
          phone: '',
          cellphone: '',
          aim: '',
          email: '',
          timezone: ''
        },
        fields: {
          id: {},
          username: {},
          company: {},
          first_name: {},
          last_name: {},
          phone: {},
          cellphone: {},
          aim: {},
          email: {},
          timezone: {}
        }
      }
    },
    created() {
      console.log("welcome to add User view");
      console.log(window.Auth.user().id);
      console.log(this.endpoints);
      this.fetchVM();
      this.fetchItem();
    },
    methods: {
      fetchVM() {
        fetch(this.endpoints.users, {
            method:'OPTIONS',
            headers: {
              'Content-Type': 'application/json',
              'X-TOKEN': Auth.token()
            }
          })
          .then(res => this.checkResponse(res))
          .then(res => res.json())
          .then(res => {
            console.log(res);
            this.fields = res.fields;
          })
          .catch(err => console.log(err));
      },
      fetchItem() {
        fetch(this.endpoints.user, {
          headers: {
            'Content-Type': 'application/json',
            'X-TOKEN': Auth.token()
          }
        })
          .then(res => this.checkResponse(res))
          .then(res => res.json())
          .then(res => {
            this.attributes = res;
          })
          .catch(err => console.log(err));
      },
      save() {
        fetch(this.endpoints.user, {
          method: 'post',
          body: JSON.stringify(this.attributes),
          headers: {
            'content-type': 'application/json',
            'X-TOKEN': Auth.token()
          }
        })
        .then(res => this.checkResponse(res))
        .then(res => res.json())
        .then( data => {
          alert('User Saved');

        })
        .catch(err => console.log(err))
      }
    }
  }
</script>
